import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from 'components/pages/CareersPage'
import { Nav } from 'components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO
      title="C++ Developer in Automotive"
      pageUrl="/careers/cppauto/"
      type="article"
      cardImage="/img/tumbacardjobs1.png"
      description="You are a kind of person that pity the fools who bash on C++ as a thing of the past. It's easy to ignore the things you cannot understand but you know it, they all know it - every high performance and mission critical system is built on C++."
    />
    <LdJsonJobPosting
      title="C++ Developer in Automotive"
      description="You are a kind of person that pity the fools who bash on C++ as a thing of the past. It's easy to ignore the things you cannot understand but you know it, they all know it - every high performance and mission critical system is built on C++."
      publishDate="2021-09-01T00:00:00.000Z"
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">C++ Developer in Automotive</h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            You are a kind of person that pity the fools who bash on C++ as a thing of the past. It's easy to ignore the things you cannot understand but you know it, they all know it - every high performance and mission critical system is built on C++. Until
            this day you praise Bjarne Stroustrup for the vision he had that changed the software industry for generations to come.
          </p>
          <p className="sm:mt-6 font-normal">
            <span className="text-primary-1">Let's talk</span> ‘cause we have an opportunity you cannot just ignore - to shape the future of mobility!
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Work on software components ensuring the reliability of in vehicle communications and state.</li>
            <li>Build a reliability framework that automates vehicle native microservice integrity and operation verification.</li>
          </ul>
          <p className="sm:mt-6 font-normal italic">What we are primarily looking for is a track record of building things that work and passion to make a better product.</p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <span className="text-primary-1">Collaborate</span> with cross functional teams to define, design and ship new features.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Understanding</span> the flow of code in the whole product and how it interacts with different components. Handle the end to end software lifecycle.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Develop</span> testing frameworks, tools and DSLs to validate the correctness and the robustness of multi components distributed software systems.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Design</span> and build a robust codebase that enables rapid exploration and evaluation of different approaches.
                </li>
                <li className="mt-4">Develop C++ based system software for Automotive projects based on Linux.</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <span className="text-primary-1">Proficiency</span> in C++14 and C++17.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Good working knowledge</span> of Python.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">5+ years</span> of professional experience in design, development, and support of system software in C++.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Advanced knowledge</span> of software engineering principles including requirements gathering, software design, source control management, build processes, code reviews, testing methods and process management
                  methodology.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Good understanding</span> of communication protocols (mainly TCP/IP), application transport and network infrastructure protocols.
                </li>
                <li className="mt-4">
                  <span className="text-primary-1">Good working knowledge</span> of Linux OS and it's network stack.
                </li>
                <li className="mt-4">The ability to recognize risks and errors in application.</li>
                <li className="mt-4">Experience with CMake and Bazel.</li>
                <li className="mt-4">Self-organised, proactive and good communicator.</li>
                <li className="mt-4">
                  Experience with DDS is <span className="text-primary-1">a big plus.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="C++ Developer in Automotive" active={false} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
